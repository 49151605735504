import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({isHomePage, children}) => {
    const {
        wp: {
            generalSettings: {title},
        },
    } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

    return (

        <main>
            <div className={"logo"}>
                <StaticImage src={"../images/logo.png"} alt={"INBEE"} />
            </div>
        </main>

        // <div className="global-wrapper" data-is-root-path={isHomePage}>
        //   <header className="global-header">
        //     {isHomePage ? (
        //       <h1 className="main-heading">
        //         <Link to="/">{parse(title)}</Link>
        //       </h1>
        //     ) : (
        //       <Link className="header-link-home" to="/">
        //         {title}
        //       </Link>
        //     )}
        //   </header>
        //
        //   <main>{children}</main>
        //
        //   <footer>
        //     © {new Date().getFullYear()}, Built with
        //     {` `}
        //     <a href="https://www.gatsbyjs.com">Gatsby</a>
        //     {` `}
        //     And <a href="https://wordpress.org/">WordPress</a>
        //   </footer>
        // </div>
    )
}

export default Layout
